import {
	type ErrorResponse,
	isRouteErrorResponse,
	useParams,
	useRouteError,
} from '@remix-run/react';
import { captureRemixErrorBoundaryError } from '@sentry/remix';
import { getErrorMessage } from '#app/utils/misc.tsx';

type StatusHandler = (info: {
	error: ErrorResponse;
	params: Record<string, string | undefined>;
}) => JSX.Element | null;

function DefaultStatusHandler({ error }: { error: ErrorResponse }) {
	return (
		<p>
			{error.status} {error.data}
		</p>
	);
}

function DefaultUnexpectedErrorHandler(error: unknown) {
	return <p>{getErrorMessage(error)}</p>;
}

export function GeneralErrorBoundary({
	defaultStatusHandler = DefaultStatusHandler,
	statusHandlers,
	unexpectedErrorHandler = DefaultUnexpectedErrorHandler,
}: {
	defaultStatusHandler?: StatusHandler;
	statusHandlers?: Record<number, StatusHandler>;
	unexpectedErrorHandler?: (error: unknown) => JSX.Element | null;
}) {
	const error = useRouteError();
	captureRemixErrorBoundaryError(error);
	const params = useParams();

	if (typeof document !== 'undefined') {
		console.error(error);
	}

	return (
		<div className="container flex items-center justify-center p-20 text-h2">
			{isRouteErrorResponse(error)
				? (statusHandlers?.[error.status] ?? defaultStatusHandler)({
						error,
						params,
					})
				: unexpectedErrorHandler(error)}
		</div>
	);
}
